<template>
  <div class="container">
    <div class="padTopHead promotionBox">
      <!-- 定位右边 -->
      <div class="pos_right" />
      <!-- 定位左边 -->
      <div class="pos_left" />
      <!-- 定位下边 -->
      <div class="pos_bottom" />
      <div class="pro_title">
        <div class="head">{{ $t('news.title') }}</div>
        <div class="desc">{{ $t('news.desc') }}</div>
      </div>
      <div
        v-for="(items, index) in datalist"
        :key="items.newsId"
        class="pro_box"
        :class="(index) % 2  === 0 ? 'single' : 'double'"
      >
        <div class="pro_items" @click="goDetail(items.newsId)">
          <div class="imgBox" :style="{order: (index) % 2 === 0 ? 1 : width996 ? 1 : 2}">
            <img :src="items.newsImage">
          </div>
          <div class="pro_detail" :style="{order: (index) % 2 === 0 ? width996 ? 1 : 2 : 1}">
            <div class="d_head">{{ $lan === 'tc' ? items.newsTitle : items.simplifiedTitle }}</div>
            <div class="d_desc vListClamp4" v-html="$lan === 'tc' ? items.newsContent : items.simplifiedContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getNews } from '../utils/request'
export default {
  name: 'News',
  data() {
    const width996 = window.innerWidth <= 996
    return {
      datalist: [],
      width996: width996
    }
  },
  mounted() {
    this.getNewsList()
  },
  methods: {
    async getNewsList() {
      const result = await getNews()
      if (result.head.success) {
        this.datalist = result.body
      } else {
        this.$message.error(result.head.message)
      }
    },
    goDetail(ID) {
      this.$router.push({
        path: '/newsdetail',
        query: {
          preferentialId: ID
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.promotionBox {
  position: relative;
  padding-bottom: 34px;
  .pro_title{
    text-align: center;
    .head{
      color: #2F2F36;
      font-size: 36px;
      font-weight: bold;
    }
    .desc{
      color: #727272;
      font-size: 20px;
    }
  }
  .single{
    padding: 4% 0;
  }
  .double{
    background: #F5F9FD;
    padding: 7% 0;
  }
  .pro_items{
    width: 57%;
    margin: 0 auto;
    cursor: pointer;
    display: flex;
    align-items: center;
    border: 1px solid #000B8C;
    border-radius: 5px;
    overflow: hidden;
    .imgBox{
      width: 530px;
      height: 190px;
      img{
        margin-top: 0;
      }
    }
    .pro_detail{
      width: 100%;
      padding: 0 7%;
     .d_head{
      font-size: 18px;
      margin-bottom: 20px;
      color: #303030;
      font-weight: bold;
     }
     .d_desc{
      margin: 0;
      color: #4F4F4F;
     }
    }
  }
  &::after{
    width: 66%;
    position: absolute;
    bottom: 0;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    content: '';
    border-top: 2px dashed #000B8C;
  }
}
.pos_right{
  width: 186px;
  height: 306px;
  position: absolute;
  top: 0;
  right: 0;
  &::before{
    position: absolute;
    top: 22px;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url("../assets/images/pro_bg_left.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  &::after{
    position: absolute;
    top: 50%;
    bottom: -50%;
    transform: translateY(-10%);
    left: -38%;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url("../assets/images/pro_radius.png");
    background-repeat: no-repeat;
  }
}
.pos_left{
  width: 162px;
  height: 252px;
  position: absolute;
  top: 16%;
  left: 0;
  &::before{
    position: absolute;
    right: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url("../assets/images/pro_bg_right.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
.pos_bottom{
  width: 136px;
  height: 142px;
  position: absolute;
  bottom: 1%;
  left: 12%;
  &::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: url("../assets/images/pro_radius.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
}
@media screen and (max-width: 1280px) {
  .promotionBox .pro_title .head{
    font-size: 30px;
  }
  .pos_bottom::before{
    left: -32%;
  }
}
@media screen and (max-width: 996px) {
  .pos_right::before,
  .pos_right::after,
  .pos_left::before,
  .pos_bottom::before
  {
    display: none;
  }
  .promotionBox .pro_items{
    width: 84%;
  }
}
@media screen and (max-width: 576px) {
  .promotionBox{
    .pro_title{
      padding: 1rem 0;
      line-height: 42px;
      .head{
        font-size: 26px;
      }
      .desc{
        font-size: 18px;
      }
    }
    .pro_items{
      flex-wrap: wrap;
      border-radius: 10px;
      .imgBox{
        width: 100%;
      }
      .pro_detail{
        padding: 7%;
        .d_head{
          font-size: 20px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &::after{
      width: 0;
    }
  }
}
</style>
